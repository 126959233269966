import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css"
import Reviews from '../../json/reviews.json'
const reviews = () => {
    const review_slider ={
        loop: true,
        margin: 10,
        nav: true,
        autoplay: true,
        dots:false,
        autoplaySpeed: 500,
        items: 1,
    }
    return (
        <div>

            <section>
                <div className="review">
                    <div className="container-fluid">
                        <h6>Clients Testimonial</h6>
                        <h4> <span>500+ </span> Customers</h4>
                        <OwlCarousel {...review_slider}  id="review_slider"  className="owl-carousel owl-theme">
                            {
                                Reviews.map((item)=>{
                                    return(
                                        <div className="wrap" key={item.id}>
                                            <p>{item.desc}</p>
                                            <h3>{item.title} </h3>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <LazyLoadImage src={item.img} alt="star"/>
                                                <span>{item.agency}</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }                      
                        </OwlCarousel>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default reviews
