import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css"
import BlogTop from "../../json/blog_top.json";

const blogslider = () => {
    const blog_slider = {
        loop: true,
        margin: 15,
        dots: false,
        autoplay: false,
        nav: false,
        mouseDrag: true,
        rewind: true,
        smartSpeed: 900,
        items: 3,
        responsive: {
            0: {
                items: 1
            },
            520: {
                items: 2
            },
            768: {
                items: 2
            },
            1024: {
                items: 3
            },
            1200: {
                items: 3
            }
        }
    }
  return (
    <div>
          <section>
              <div className="blog_card">
                  <div className="container-fluid">
                      <OwlCarousel {...blog_slider} id="blog_slider" class="owl-carousel owl-theme">
                          {
                              BlogTop.map((item) => {
                                  return (
                                    <div className="item">
                                        <LazyLoadImage src={item.img} alt="blog" />
                                        <div className="p-3">
                                            <h3>{item.title}
                                                <Link to="/"><i className="fal fa-long-arrow-right"></i></Link>
                                            </h3>
                                            <p>{item.desc}</p>
                                            <span><b>{item.name}</b> {item.detail}</span>
                                        </div>
                                    </div>
                                  )
                              })
                          }
                      </OwlCarousel>
                  </div>
              </div>
          </section>
    </div>
  )
}

export default blogslider
