import React from 'react'
import $ from 'jquery'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {  Link } from 'react-router-dom';
import Logo from '../assets/images/logo.webp';
import Addons from '../component/Home/addons';
import Zendesk, { ZendeskAPI } from "../ZedeskConfig";

const header = ({ Contactstyle }) => {
  const ZENDESK_KEY = "2adf497a-45bf-46ff-94ba-37d5781e8fe1";

  const handleLoaded = () => {
    ZendeskAPI('webWidget:on', 'open', function () {
      console.log('The widget has been opened!');
    });
    // alert('test');
  };

  const dropDown =()=>{
   $(' .dropdown').hover(function () {
     $('.dropdown-menu').toggle(200);
    //  console.log('geegegeggge');
   });
 }
    return (
    <>
      <Addons/>
      <div className="top_bar">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-4 col-xl-4 col-xxl-4 col-md-12">
              <ul>
                <li><Link to="tel:+1 (408)-785-6680"><i className="fas fa-phone-alt"></i>+1 (408)-785-6680</Link></li>
                <li><Link to="mailto:sales@futureisapp.com"><i className="fas fa-at"></i>sales@futureisapp.com</Link></li>
              </ul>
            </div>
            <div className="col-12 col-sm-12 col-lg-8 col-xl-8 col-xxl-8 col-md-12">
              <p>
                <marquee behavior="">
                  OUR HIGH-END MOBILE APP DEVELOPMENT SERVICES
                </marquee>
              </p>
            </div>
          </div>
        </div>
      </div>
      <header style={Contactstyle}>
        <nav className="navbar navbar-expand-lg ">
          <div className="container-fluid">
            <Link className="navbar-brand" to="#"><LazyLoadImage src={Logo} alt="logo"/></Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <i className="far fa-bars"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav m-auto">
                <li className="nav-item">
                  <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link " aria-current="page" to="/about">About</Link>
                </li>
                <li className="nav-item dropdown " onClick={dropDown}>
                  <Link className="nav-link dropdown-toggle" to="/services" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Services
                  </Link>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link className="dropdown-item" to="/web-app-development">Web App Development</Link></li>
                    <li><Link className="dropdown-item" to="/android-app-development"> Android App Development</Link></li>
                    <li><Link className="dropdown-item" to="/game-app-development">Game App Development</Link></li>
                    <li><Link className="dropdown-item" to="/nft-app-development">NFT App development</Link></li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/portfolio">Portfolio</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/blog">Blog</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">Contact</Link>
                </li>
              </ul>
              <div className="call_btns">
                <Link to="/">Let's Chatss <i className="fal fa-long-arrow-up"></i></Link>
                <Link to="tel:+1 (408)-785-6680">Schedule A Call</Link>
              </div>
            </div>
          </div>
        </nav>
      </header>
        <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={handleLoaded} />

    </>
  )
}

export default header
