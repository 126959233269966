import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import Impact from '../../assets/images/impact.webp';

const cta = () => {
  return (
    <div>
          <section class="cta_banner about">
              <div class="container-fluid">
                  <div class="row">
                      <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div class="cta_img">
                              <LazyLoadImage src={Impact} alt="img"/>
                          </div>
                      </div>
                      <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">

                          <div class="cta_content">
                              <h2> Our Impact .</h2>
                              <p>At Future is Apps, we are passionate about making a positive impact through our mobile solutions. We strive to empower businesses and individuals by creating apps that enhance their digital presence, drive growth, and improve user experiences. With us, you can expect tangible results and a meaningful impact on your business or personal endeavors.</p>
                              <div class="btns">
                                  <Link to="tel:+1 (408)-785-6680"> Schedule A Call </Link>
                                  <Link to="/"> Let's chat <i class="fal fa-long-arrow-up"></i></Link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
    </div>
  )
}

export default cta
