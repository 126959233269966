import React from 'react'

const upgrade = () => {
  return (
    <div>
      <section>
        <div className="process_area">
          <div className="container-fluid">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-sm-12 col-lg-4 col-xl-4 col-xxl-4 col-md-4">
                <div className="txt">
                  <h2> How We <span>Upgrade You <em>.</em> </span></h2>
                  <p>Empowering Brands with Application Technology</p>
                  <div className="btns">
                    <a href="tel:+1 (408)-785-6680">Schedule A Call</a>
                    <a href="void:;" >Let's Chat <i className="fal fa-long-arrow-up"></i></a>
                  </div>
                </div>

              </div>
              <div className="col-12 col-sm-12 col-lg-8 col-xl-8 col-xxl-8 col-md-8">
                <div className="process_box">
                  <div className="box_1">
                    <i className="far fa-search"></i>
                    <h4>Comprehensive Research </h4>
                  </div>
                  <div className="box_1">
                    <i className="fad fa-pencil-paintbrush"></i>
                    <h4>Innovative Designs</h4>
                  </div>
                  <div className="box_1">
                    <i className="fal fa-rocket-launch"></i>
                    <h4>Alpha Testing</h4>
                  </div>
                  <div className="box_1">
                    <i className="fad fa-file-code"></i>
                    <h4>Bug-Free Development </h4>
                  </div>
                  <div className="box_1">
                    <i className="far fa-clipboard-list-check"></i>
                    <h4>Beta Testing</h4>
                  </div>
                  <div className="box_1">
                    <i className="far fa-clipboard-list-check"></i>
                    <h4>Application Launch</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default upgrade
