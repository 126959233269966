import React from 'react'
import Footer from '../includes/footer';
import Header from '../includes/header';
import Reviews from '../component/Home/reviews';
import Award from '../component/Home/award';
import Partner from '../component/Services/Nft/partner';
import Process from '../component/Services/Nft/process';
import Cta from '../component/Services/Nft/cta';
import Services from '../component/Services/Nft/services';
import Banner from '../json/services.json';
import { Link } from 'react-router-dom'
const gameappdevelopment = () => {
    return (
        <div>
            <Header />
            {/* banner  */}
            <section>
                <div className="about_banner services">
                    <div className="container-fluid">
                        <div className="row d-flex align-items-center">
                            <div className="col-12 col-sm-12 col-lg-7 col-xl-7 col-xxl-7 col-md-7">
                                <div className="txt">
                                    <h1>{Banner[4].title}</h1>
                                    <p>{Banner[4].desc}</p>
                                    <div className="btns">
                                        <Link to="tel:+1 (408)-785-6680 ">Schedule A Call</Link>
                                        <Link to="/">Let's Chat <i className="fal fa-long-arrow-up"></i></Link>
                                    </div>
                                    <h6>Trusted By <b>Innovator &amp; Industry</b> Leaders!</h6>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-5 col-xl-5 col-xxl-5 col-md-5">
                                <div className="services_form">
                                    <h4>Get A Free App
                                        <span>Prototype &amp; Consultation</span>
                                    </h4>
                                    <div className="row">
                                        <form action="https://leads.infinityprojectmanager.com/brand/futureisapp.com/lead" className="popup-form CrudForm cmxform">
                                            <input type="hidden" name="brand_name" value="https://futureisapp.com" />
                                            <input type="hidden" id="lead_area" name="lead_area" value="https://futureisapp.com" />
                                            <div className="row">
                                                <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                                                    <label>Name</label>
                                                    <input name="name" placeholder="John Doe" required="" />
                                                </div>
                                                <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                                                    <label>Email</label>
                                                    <input type="email" name="Email" placeholder="Example@example.com" required="" />
                                                </div>
                                                <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                                                    <label>Contact Number</label>
                                                    <input type="tel" name="phone" minLength="9" maxLength="11" placeholder="+1 (234) 567890" data-inputmask="'mask': '(999) 999-9999'" required="" />

                                                </div>
                                                <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                                                    <label>Message</label>
                                                    <textarea name="message" rows="7" placeholder="Text Here..."></textarea>
                                                </div>
                                                <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                                                    <button type="submit">Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* banner  */}
            <Services />
            <Process />
            <Cta />
            <Award />
            <Partner />
            <Reviews />
            <Footer />
        </div>
    )
}

export default gameappdevelopment
