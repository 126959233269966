import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ic1 from '../../assets/images/ic1.webp';
import ic2 from '../../assets/images/ic2.webp';
import ic3 from '../../assets/images/ic3.webp';
import ic4 from '../../assets/images/ic4.webp';
import ic5 from '../../assets/images/ic5.webp';
import ic6 from '../../assets/images/ic6.webp';
import ic7 from '../../assets/images/ic7.webp';
import ic8 from '../../assets/images/ic8.webp';
import game from '../../assets/images/game.webp';
const Technology = () => {
  return (
    <div>
       <section>
        <div className="technologies">
            <div className="container-fluid">
                <h3>No Tech Is Out of <span> Our Reach </span> </h3>


                <div className="languages">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="Mobile-tab" data-bs-toggle="tab" data-bs-target="#Mobile" type="button" role="tab" aria-controls="Mobile" aria-selected="true">Mobile</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="Front-tab" data-bs-toggle="tab" data-bs-target="#Front" type="button" role="tab" aria-controls="Front" aria-selected="false">Front-end</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="Database-tab" data-bs-toggle="tab" data-bs-target="#Database" type="button" role="tab" aria-controls="Database" aria-selected="false">Database</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="Back-tab" data-bs-toggle="tab" data-bs-target="#Back" type="button" role="tab" aria-controls="Back" aria-selected="false">Back-end</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="CMS-tab" data-bs-toggle="tab" data-bs-target="#CMS" type="button" role="tab" aria-controls="CMS" aria-selected="false">CMS</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="Infra-tab" data-bs-toggle="tab" data-bs-target="#Infra" type="button" role="tab" aria-controls="Infra" aria-selected="false">Infra & Devops</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="Mobile" role="tabpanel" aria-labelledby="Mobile-tab">
                                <LazyLoadImage src={ic1} alt="icon"/>
                                <LazyLoadImage src={ic2} alt="icon" />
                                <LazyLoadImage src={ic3} alt="icon" />
                                <LazyLoadImage src={ic4} alt="icon" />
                                <LazyLoadImage src={ic5} alt="icon" />
                                <LazyLoadImage src={ic6} alt="icon" />
                                <LazyLoadImage src={ic7} alt="icon" />
                                <LazyLoadImage src={ic8} alt="icon" />
                                <LazyLoadImage src={game} alt="icon" />
                        </div>
                        <div className="tab-pane fade" id="Front" role="tabpanel" aria-labelledby="Front-tab">
                                  <LazyLoadImage src={ic1} alt="icon" />
                                  <LazyLoadImage src={ic2} alt="icon" />
                                  <LazyLoadImage src={ic3} alt="icon" />
                                  <LazyLoadImage src={ic4} alt="icon" />
                                  <LazyLoadImage src={ic5} alt="icon" />
                                  <LazyLoadImage src={ic6} alt="icon" />
                                  <LazyLoadImage src={ic7} alt="icon" />
                                  <LazyLoadImage src={ic8} alt="icon" />
                                  <LazyLoadImage src={game} alt="icon" />
                        </div>
                        <div className="tab-pane fade" id="Database" role="tabpanel" aria-labelledby="Database-tab">
                                  <LazyLoadImage src={ic1} alt="icon" />
                                  <LazyLoadImage src={ic2} alt="icon" />
                                  <LazyLoadImage src={ic3} alt="icon" />
                                  <LazyLoadImage src={ic4} alt="icon" />
                                  <LazyLoadImage src={ic5} alt="icon" />
                                  <LazyLoadImage src={ic6} alt="icon" />
                                  <LazyLoadImage src={ic7} alt="icon" />
                                  <LazyLoadImage src={ic8} alt="icon" />
                                  <LazyLoadImage src={game} alt="icon" />
                        </div>
                        <div className="tab-pane fade" id="Back" role="tabpanel" aria-labelledby="Back-tab">
                                  <LazyLoadImage src={ic1} alt="icon" />
                                  <LazyLoadImage src={ic2} alt="icon" />
                                  <LazyLoadImage src={ic3} alt="icon" />
                                  <LazyLoadImage src={ic4} alt="icon" />
                                  <LazyLoadImage src={ic5} alt="icon" />
                                  <LazyLoadImage src={ic6} alt="icon" />
                                  <LazyLoadImage src={ic7} alt="icon" />
                                  <LazyLoadImage src={ic8} alt="icon" />
                                  <LazyLoadImage src={game} alt="icon" />
                        </div>
                        <div className="tab-pane fade" id="CMS" role="tabpanel" aria-labelledby="CMS-tab">
                                  <LazyLoadImage src={ic1} alt="icon" />
                                  <LazyLoadImage src={ic2} alt="icon" />
                                  <LazyLoadImage src={ic3} alt="icon" />
                                  <LazyLoadImage src={ic4} alt="icon" />
                                  <LazyLoadImage src={ic5} alt="icon" />
                                  <LazyLoadImage src={ic6} alt="icon" />
                                  <LazyLoadImage src={ic7} alt="icon" />
                                  <LazyLoadImage src={ic8} alt="icon" />
                                  <LazyLoadImage src={game} alt="icon" />
                        </div>
                        <div className="tab-pane fade" id="Infra" role="tabpanel" aria-labelledby="Infra-tab">
                                  <LazyLoadImage src={ic1} alt="icon" />
                                  <LazyLoadImage src={ic2} alt="icon" />
                                  <LazyLoadImage src={ic3} alt="icon" />
                                  <LazyLoadImage src={ic4} alt="icon" />
                                  <LazyLoadImage src={ic5} alt="icon" />
                                  <LazyLoadImage src={ic6} alt="icon" />
                                  <LazyLoadImage src={ic7} alt="icon" />
                                  <LazyLoadImage src={ic8} alt="icon" />
                                  <LazyLoadImage src={game} alt="icon" />
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </section>
    </div>
  )
}

export default Technology
