import './App.css';
import { Route, Routes } from "react-router-dom"
// import Zendesk, { ZendeskAPI } from "./ZedeskConfig";
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Portfolio from './Pages/Portfolio';
import Blog from './Pages/Blog';
import PrivacyPolicy from './Pages/privacypolicy';
import TermsConditions from './Pages/termsconditions';
// services 
import WebAppDevelopment from './Pages/webappdevelopment';
import AndroidAppDevelopment from './Pages/androidappdevelopment';
import GameAppDevelopment from './Pages/gameappdevelopment';
import NftAppDevelopment from './Pages/termsconditions';
import CrossPlatform from './Pages/crossplatform';


// services 


// const ZENDESK_KEY = "bdc23535-5173-4c6f-b2c0-f40dce54897f";


function App() {
  // const handleLoaded = () => {
  //   ZendeskAPI("messenger", "open");
  // };

  return ( 
    <>
      {/* <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={handleLoaded} /> */}
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/Portfolio" element={<Portfolio />} />
                <Route path="/web-app-development" element={<WebAppDevelopment />} />
                <Route path="/android-app-development" element={<AndroidAppDevelopment />} />
                <Route path="/game-app-development" element={<GameAppDevelopment />} />
                <Route path="/nft-app-development" element={<NftAppDevelopment />} />
                <Route path="/cross-platform-app-development" element={<CrossPlatform />} />
            <Route path="/Blog" element={<Blog />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
        </Routes>
    </>
  );
}

export default App;
