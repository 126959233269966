import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import blog_img from '../../assets/images/blog.webp'

const banner = () => {
  return (
    <div>
          <section>
              <div className="about_banner blog">
                  <div className="container-fluid">
                      <div className="row d-flex align-items-center">
                          <div className="col-12 col-sm-12 col-lg-6 col-xl-6 col-xxl-6 col-md-6">
                              <div className="txt">
                                  <span>Our Blog!</span>
                                  <h1>We Help
                                      Businesses. <i>.</i>
                                  </h1>
                                  <p>Keep Up To Date With The Latest Tech Insights, Business Guidelines And Trending News Articles, And Stay Tech-Savvy!</p>
                                  <div className="btns">
                                      <Link to="/">Read Latest Blogs <i className="fal fa-long-arrow-up"></i></Link>
                                  </div>
                              </div>
                          </div>
                          <div className="col-12 col-sm-12 col-lg-6 col-xl-6 col-xxl-6 col-md-6">
                              <div className="img_banner">
                                  <LazyLoadImage src={blog_img} alt="blog"/>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
         </section>
    </div>
  )
}

export default banner
