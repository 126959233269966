import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Cta from '../../assets/images/cta.webp';

const cta = () => {
  return (
    <div>
          <section className="cta_banner">
              <div className="container-fluid">
                  <div className="row">
                      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="cta_content">
                              <span>Which ever Platform You Need</span>
                              <h2><span>Ensuring Customers</span> Reach You from Anywhere They Want </h2>
                              <p>Providing top-notch products and services is only half of the success equation. The other half is being conveniently accessible for all your customers. We can help with that. </p>
                              <div className="btns">
                                  <a href="tel:+1 (408)-785-6680 "> Schedule A Call </a>
                                  <a > Let's chat <i className="fal fa-long-arrow-up"></i></a>
                              </div>
                          </div>
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="cta_img">
                              <LazyLoadImage src={Cta} alt="img"/>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
    </div>
  )
}

export default cta
