import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import BuzzFeed from '../../assets/images/BuzzFeed-logo.webp';
import Mashable from '../../assets/images/mashable-logo.webp';
import Yahoo from '../../assets/images/yahoo-logo.webp';

const media = () => {
  return (
    <div>
          <section>
              <div className="media_mention">
                  <div className="container-fluid">
                      <div className="txt_head">
                          <h5><span>Media</span> Mentions.</h5>
                          <p>We Stay In The News For Our Incredible Tech Achievements.</p>
                      </div>
                      <div className="crds">
                          <div className="crd">
                              <LazyLoadImage src={BuzzFeed} alt="buzz"/>
                                  <p>There's Finally A Dating App For People With Disabilities</p>
                          </div>
                          <div className="crd">
                              <LazyLoadImage src={Mashable} alt="mashable"/>
                                  <p>A New App Just Launched To Make Online Dating More Inclusive</p>
                          </div>
                          <div className="crd">
                              <LazyLoadImage src={Yahoo} alt="yahoo"/>
                                  <p>This Startup Helps You Donate To Charity When You See An Infuriating Tweet</p>
                          </div>
                      </div>

                  </div>
              </div>
          </section>
    </div>
  )
}

export default media
