import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from 'react-router-dom';
import home_award from "../../json/home_award.json";

const award = () => {
    const slider_award ={
        loop: true,
        margin: 0,
        nav: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 500,
        items: 5,
        responsive: {
            0: {
                items: 1
            },
            520: {
                items: 3
            },
            768: {
                items: 3
            },
            1024: {
                items: 4
            },
            1200: {
                items: 5
            }
        }
    }
    return (
        <div>
            <section>
                <div className="awards">
                    <div className="container-fluid">
                        <div className="row d-flex align-items-center">
                            <div className="col-12 col-sm-12 col-lg-8 col-xl-8 col-xxl-8 col-md-8">
                                <div className="txt">
                                    <h5>Awards <span>& Recognitions </span></h5>
                                    <p>While we may not have any Nobel Prizes to our name, we do have many awards for planning, designing, and developing ground-breaking applications that set new trends!</p>
                                    <Link to="void:;">Read Reviews <i className="fal fa-long-arrow-up"></i></Link>

                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-4 col-xl-4 col-xxl-4 col-md-4">
                                <div className="rating">
                                    <h5>On Google
                                        <ul>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                            <li><i className="fas fa-star"></i></li>
                                        </ul>
                                    </h5>
                                    <h4>4.9 <span>850 Reviews</span></h4>

                                </div>
                            </div>
                        </div>
                        <OwlCarousel {...slider_award} id="slider_award" className="owl-carousel owl-theme">
                            {
                                home_award.map((item)=>{
                                    return(
                                        <div key={item.id}>
                                            <LazyLoadImage src={item.img} alt="Logo"/>
                                        </div>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default award
