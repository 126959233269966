import React from 'react'
import { Link } from 'react-router-dom';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css"

const services = () => {
    const services_card ={
        loop: true,
        margin: 10,
        nav: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
        items: 4,
        responsive: {
            0: {
                items: 1
            },
            520: {
                items: 2
            },
            768: {
                items: 2
            },
            1024: {
                items: 3
            },
            1200: {
                items: 4
            }
        }
    }
  return (
    <div>
          <section>
              <div class="services">
                  <div class="container-fluid">
                      <div class="head_txt">
                          <h3><span>Our</span> Services <span>.</span></h3>
                          <p>Unleash Your NFT Potential with Our Comprehensive Services.</p>
                      </div>
                      <OwlCarousel {...services_card} id="services_card" class="owl-carousel owl-theme">
                          <div class="crd">
                              <i class="fab fa-apple"></i>
                              <h4>Conceptualization and Design</h4>
                              <p>Our creative team ideates and designs your NFT app, ensuring it aligns perfectly with your vision and market expectations.</p>
                              <Link to="/"> Let's chat <i class="fal fa-long-arrow-up"></i></Link>
                          </div>
                          <div class="crd">
                              <i class="fab fa-android"></i>
                              <h4>Front-end Development</h4>
                              <p>Experience flawless, user-friendly interfaces crafted by our experts. We prioritize aesthetics, usability, and engagement in our front-end development.</p>
                              <Link to="/"> Let's chat <i class="fal fa-long-arrow-up"></i></Link>
                          </div>
                          <div class="crd">
                              <i class="fas fa-desktop-alt"></i>
                              <h4>Back-end Development</h4>
                              <p>Our robust back-end development ensures your NFT app handles transactions securely, maintains user data, and runs smoothly, irrespective of the load.</p>
                              <Link to="/"> Let's chat <i class="fal fa-long-arrow-up"></i></Link>
                          </div>
                          <div class="crd">
                              <i class="far fa-sensor-fire"></i>
                              <h4>Marketplace Functionality</h4>
                              <p>We integrate marketplace functionality to facilitate easy trading of NFTs, enabling users to buy, sell, or trade digital assets with complete ease.</p>
                              <Link to="/"> Let's chat <i class="fal fa-long-arrow-up"></i></Link>
                          </div>
                          <div class="crd">
                              <i class="far fa-sensor-fire"></i>
                              <h4>Security and Scalability</h4>
                              <p>Leveraging blockchain technology, we ensure your NFT app is secure from threats. Additionally, we build scalable solutions to accommodate your platform's growth.</p>
                              <Link to="/"> Let's chat <i class="fal fa-long-arrow-up"></i></Link>
                          </div>
                          <div class="crd">
                              <i class="far fa-sensor-fire"></i>
                              <h4>Testing and Deployment</h4>
                              <p>Our rigorous testing protocols ensure every aspect of your NFT app functions perfectly before deployment.</p>
                              <Link to="/"> Let's chat <i class="fal fa-long-arrow-up"></i></Link>
                          </div>
                          <div class="crd">
                              <i class="far fa-sensor-fire"></i>
                              <h4>Ongoing Maintenance and Support</h4>
                              <p>We provide comprehensive support and regular updates post-deployment, ensuring your NFT app remains up-to-date and continues to deliver top-notch performance.</p>
                              <Link to="/"> Let's chat <i class="fal fa-long-arrow-up"></i></Link>
                          </div>
                      </OwlCarousel>
                  </div>
              </div>
          </section>
    </div>
  )
}

export default services
