import React from 'react'
import { Link } from 'react-router-dom';

const banner = () => {
  return (
    <div>
          <section>
              <div className="about_banner portfolio">
                  <div className="container-fluid">
                      <div className="txt">
                          <span>Made With Passion</span>
                          <h1>Portfolio <i>.</i></h1>
                          <p>Future Is App Is An Extension Of Your Marketing Team – Bringing Strategy, Expertise,
                              And Execution To The Areas That You Need It Most.</p>
                          <div className="btns">
                              <Link to="tel:+1 (408)-785-6680 ">Schedule A Call</Link>
                              <Link to="/" >Let's Chat <i className="fal fa-long-arrow-up"></i></Link>
                          </div>
                      </div>
                  </div>
              </div>
            </section>
    </div>
  )
}

export default banner
