import React from 'react'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from 'react-router-dom';
import home_services from "../../json/home-services.json";
const services = () => {
    const services_card={
        loop: true,
        margin: 10,
        nav: false,
        dots: false,
        autoplay: false,
        autoplaySpeed: 2000,
        items: 4,
        responsive: {
            0: {
                items: 1
            },
            520: {
                items: 2
            },
            768: {
                items: 2
            },
            1024: {
                items: 3
            },
            1200: {
                items: 4
            }
        }
    }
  return (
    <div>
          <section>
              <div className="services">
                  <div className="container-fluid">
                      <div className="head_txt">
                          <h3><span>Our</span> Services <span>.</span></h3>
                          <p>Customized Mobile App Development Services & Digital Solutions</p>
                      </div>
                      <OwlCarousel {...services_card} className="owl-carousel owl-theme" id="services_card">
                        {
                            home_services.map((item)=>{
                                return(
                                    <div className="crd" key={item.id}>
                                        <i className="far fa-sensor-fire"></i>
                                        <h4>{item.title}</h4>
                                        <p>{item.desc}</p>
                                        <Link to="/" > {item.btn}<i className="fal fa-long-arrow-up"></i></Link>
                                    </div>
                                )

                            })
                        }
                    
                      </OwlCarousel>
                  </div>
              </div>
          </section>
    </div>
  )
}

export default services
