import React from 'react'
import { Link } from 'react-router-dom'

const cta = () => {
  return (
    <div>
          <section>
              <div class="cta">
                  <h6>Delve into Our Triumphs in
                      <span> the NFT Space!</span>
                      <Link to="tel:+1 (408)-785-6680">Get A Call <i class="fal fa-long-arrow-up"></i></Link>
                  </h6>
              </div>
          </section>
    </div>
  )
}

export default cta
