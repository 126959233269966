import React from 'react'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Services from "../../json/about_services.json";

const services = () => {
    const mvv_slider={
        loop: true,
        margin: 15,
        dots: false,
        autoplay: true,
        nav: false,
        mouseDrag: true,
        rewind: true,
        smartSpeed: 900,
        items: 3,
        responsive: {
            0: {
                items: 1
            },
            520: {
                items: 2
            },
            768: {
                items: 2
            },
            1024: {
                items: 3
            },
            1200: {
                items: 3
            }
        }
    }
  return (
    <div>
          <section>
              <div class="mvv_wrap">
                  <div class="container-fluid">
                      <OwlCarousel {...mvv_slider} id="mvv_slider" class="owl-carousel owl-theme">
                        {
                              Services.map((item)=>{
                                return(
                                    <div class="item">
                                        <h3>{item.title} <i>.</i></h3>
                                        <h6>{item.subtitle}</h6>
                                        <p>{item.desc}</p>
                                    </div>
                                )
                            })
                        }
                         
                      </OwlCarousel>
                  </div>
              </div>
          </section>
    </div>
  )
}

export default services
