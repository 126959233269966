import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom';
import nft_process from "../../../json/nft-process.json";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css"

const process = () => {
    const process_slider ={
        loop: true,
        margin: 15,
        dots: false,
        autoplay: false,
        nav: true,
        mouseDrag: true,
        rewind: true,
        smartSpeed: 900,
        items: 4,
        responsive: {
            0: {
                items: 1
            },
            520: {
                items: 2
            },
            768: {
                items: 2
            },
            1024: {
                items: 4
            },
            1200: {
                items: 4
            }
        }
    }
  return (
    <div>
          <section>
              <div class="process_area services">
                  <div class="container-fluid">
                      <div class="txt">
                          <h2> Our Comprehensive <span>NFT App Development Process <em>.</em> </span></h2>
                          <p>Propel Your NFT Venture with Our Client-Centric Approach.</p>
                      </div>
                      <OwlCarousel {...process_slider} id="process_slider" class="owl-carousel owl-theme">
                        {
                            nft_process.map((item)=>{
                                return(
                                    <div class="item">
                                        <h3>{item.title} </h3>
                                        <p>{item.desc}</p>
                                        <div class="btns">
                                            <Link to="/">Learn More <i class="fal fa-long-arrow-right"></i></Link>
                                            <LazyLoadImage src={item.img} alt="not found"/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                         
                      </OwlCarousel>
                  </div>
              </div>
          </section>
    </div>
  )
}

export default process
