import React from 'react'
import Header from '../includes/header';
import Footer from '../includes/footer';
import Portfolio from '../component/Portfolio/portfolio';
import Banner from '../component/Portfolio/banner';
import Reviews from '../component/Home/reviews';

const portfolio = () => {
  return (
    <div>
          <Header />
          <Banner />
          <Portfolio />
          {/* cta  */}
          <section>
              <div className="cta">
                  <h6>Get Your Brand the App It Needs Because Your Customers
                      <span>Won't Keep on Waiting</span>
                      <a href="tel:+1 (408)-785-6680">Get Your App Here <i className="fal fa-long-arrow-up"></i></a>
                  </h6>
              </div>
          </section>
          {/* cta  */}
          <Reviews />
          <Footer/>
    </div>
  )
}

export default portfolio
