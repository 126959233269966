import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../includes/header';
import Footer from '../includes/footer';
const termsconditions = () => {
    return (
        <div>
            <Header/>
            <main>
                <section>
                    <div className="about_banner portfolio">
                        <div className="container-fluid">
                            <div className="txt">
                                <h1>Terms and Conditions <i>.</i></h1>
                                <p>By accessing or using our Website, you agree to be bound by these Terms and Conditions of Use ("Agreement").</p>
                                <div className="btns">
                                    <Link to="tel:+1 (408)-785-6680 ">Schedule A Call</Link>
                                    <Link to="void:;" >Let's Chat <i className="fal fa-long-arrow-up"></i></Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section>
                    <div className="privacy_txt">
                        <div class="container-fluid">
                            <p>By accessing or using our Website, you agree to be bound by these Terms and Conditions of Use ("Agreement"). This Agreement constitutes a legally binding agreement between you and the Website Operator regarding your use of the Website and/or Services. Therefore, please read this Agreement carefully before accessing or using the Website. If you do not want to be bound by this Agreement, please do not access or use the Website.</p>
                            <p>You agree to all the terms and conditions stated here by continuing to access this Website. Please stop using our site immediately if there is anything in these Website Standard Terms and Conditions you do not agree with.</p>
                            <h4>Restrictions</h4>
                            <p>You are specifically restricted from all of the following:</p>
                            <ul>
                                <li>You cannot publish any material from this Website in another media outlet.</li>
                                <li>Commercializing any Website material through means such as selling or sublicensing.</li>
                                <li>Publicly performing and/or showing any Website material.</li>
                                <li>Using this Website in any way that is or may be damaging to this Website.</li>
                                <li>Using this Website in any way that impacts user access to this Website.</li>
                                <li>Using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website or to any person or business entity.</li>
                                <li>You may not collect, synthesize, process or analyze any personal information from this site;</li>
                                <li>We will take appropriate action if you use this Website to post any commercial content.</li>
                                <li>Access to some sections of this Website is restricted, and Future is APPS may decide to restrict access to other site areas at any time.</li>
                            </ul>

                            <h3>Age requirement</h3>
                            <p>You must be 16 years of age or older to use this Website. If you are using this Website and agree to this Agreement, you confirm that you are at least 18 years old.</p>
                            <h3>Links to other websites</h3>
                            <p>Although this Website may link to other websites, none of those endorsements should be assumed unless stated otherwise. We are not responsible for the actions or content of any linked website. Likewise, we aren't responsible for any other websites' actions, products, services, or content. You should always read the legal conditions of every Website you visit that's linked to this one. Your own risk is at play when you follow any links to other sites not under our control.</p>
                            <h3>Prohibited uses</h3>
                            <p>You are not permitted to use the Website or its Content for any illegal purpose, including but not limited to: (a) for an unlawful cause; (b) to induce others to perform or take part in criminal acts; (c) in violation of any international, federal, provincial, state, or local regulations, rules, laws, or local ordinances. (d) to go against our intellectual property rights or the intellectual property rights of others; (e) to hassle, mistreat, offend, injure, defame, slanderous remarks, disparage based on gender. (g) to upload or transmit viruses that will affect the functionality of the Service, related websites, other websites, or the Internet; (h) to collect or track others' personal information. (i) Do not spam, phish, pharm, pretext, spider, crawl or scrape the Website. (j) Do not use for any obscene or immoral purpose; (k) Do not interfere with or circumvent the security features of this Service or any other related websites. We have the right to terminate your use of our Service if you violate any of these prohibited usesvv.</p>
                            <h3>Intellectual property rights</h3>
                            <p>You will not own any intellectual property just by reading our Website or utilizing our services. Furthermore, all trademarks, service marks, graphics, and logos used in connection with our Website or Services belong to us or our licensors. In addition, our site and services use trademarks, service marks, logos, and other graphics that may be the property of third parties. You obtain no rights or license to reproduce or utilize any Website Operator or third-party trademarks as a result of your usage of our Website and services.</p>
                            <h3>Limitation of liability</h3>
                            <p>To the fullest extent that the law permits, the Website Operator, its affiliates, officers, directors, and employees' agents will not be held liable to anyone for any (a): punitive damages resulting from lost profits ((including revenue,) sales goodwill), business interruption or opportunity), incurred by use of content. We may not be held liable for any damages resulting from the use of this Website, even if the Website Operator has been notified about the possibility of such injuries or could have anticipated them. To the greatest extent permissible by law, the Website Operator and its affiliates, officers, employees, agents, suppliers, and licensors will be held harmless against any claims or damages based on the services. This insurance does not apply to you if you are a minor (under 18 years old).</p>
                            <h3>Indemnification</h3>
                            <p>You agree not to hold Website Operator or any of its affiliates, directors, officers, employees, and agents responsible for any liabilities, losses, or damage costs that may arise from third-party allegations relating to your use of the content or services on this Website.</p>
                            <h3>Severability</h3>
                            <p>The following limitations apply to all rights and restrictions discussed in this Agreement: they can only be exercised insofar as they don't violate applicable laws. The provisions of this Agreement are only meant to be limited as much as necessary so that they won't become illegal, invalid, or unenforceable. The parties agree that if any part of this Agreement is illegal, invalid, or unenforceable in court, the remaining provisions will still apply and be binding.</p>
                            <h3>Dispute resolution</h3>
                            <p>The laws of California and the United States shall govern any interpretation or disagreement arising from this Agreement. The exclusive jurisdiction and venue for actions involving the subject matter of this Agreement shall be the courts in California, United States, and you, at this moment, submit to their jurisdiction. You now waive any right to a jury trial in any action related to or connected to this Contract. This Agreement is not governed by the United Nations Convention on Contracts for the International Sale of Goods.</p>
                            <h3>Changes and amendments</h3>
                            <p>You and we agree that the Website and Services are provided "as-is" without any warranties. You accept responsibility for your communications using the Website, as well as your interactions with other users of the network. Any postings you make on or through the Website, including user reviews, photographs, or comments, are subject to this Acceptable Use Policy. We may change this Acceptable Use Policy or related policies relating to the Website or services at any time; if we do so, we will notify you by email. Continued use of the Website following any modifications demonstrates your Agreement to such changes.</p>
                            <h3>Acceptance of these terms</h3>
                            <p>By reading this document and continuing to use the Website or Services, you agree to be legally bound by this Agreement. Therefore, please stop using our site immediately if you do not wish to follow these terms.</p>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />

        </div>
    )
}

export default termsconditions
