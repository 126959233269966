import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css"
import banner_slider_data from '../../json/banner_slider_data.json'


const Banner = () => {
    const banner_slider = {
        loop: true,
        margin: 10,
        dots: false,
        autoplay: false,
        nav: false,
        mouseDrag: true,
        rewind: true,
        smartSpeed: 900,
        items: 3,
        responsive: {
            0: {
                items: 1
            },
            520: {
                items: 2
            },
            768: {
                items: 2
            },
            1024: {
                items: 3
            },
            1200: {
                items: 3
            }
        }
    }
  return (
    <div>
          {/* <!-- banner   --> */}
          <section>
              <div className="container-fluid">
                  <div className="banner_area">
                      <div className="head_txt">
                          <h1>Be Accessible Through All <span>Platforms for Your <b> Customers</b></span></h1>
                          <div className="btns">
                              <Link to="tel: +1 (408)-785-6680 ">Schedule A Call</Link>
                              <Link to="void:;" >Let's Chat <i className="fal fa-long-arrow-up"></i></Link>
                          </div>
                      </div>

                  </div>
              </div>
              <OwlCarousel {...banner_slider} className="owl-carousel owl-theme" id="banner_slider">
                  {
                      banner_slider_data.map((item) => {
                          return (
                              <div key={item.id}>
                                  <LazyLoadImage src={item.img} alt="Img Not Found" />
                              </div>
                          )
                      })
                  }
              </OwlCarousel>
          </section>
          {/* <!-- banner  --> */}
      
    </div>
  )
}

export default Banner
