import React from 'react'
import $ from 'jquery'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom';
import Experiece from '../../assets//images/experiece.webp';
const addons = () => {

    const sidebar = () => {
        $(".floatbutton").toggleclassName("active")
        $(".crossplus").toggleclassName("rotate")
    }

    const scroll=()=>{
        $(window).on("scroll", function () {
            if ($(".stricked-menu").length) {
                var o = $(".stricked-menu");
                130 < $(window).scrollTop() ? o.addclassName("stricky-fixed") : $(this).scrollTop() <= 130 && o.removeclassName("stricky-fixed")
            }
            if ($(".scroll-to-top").length) {
                100 < $(window).scrollTop() ? $(".scroll-to-top").fadeIn(500) : $(this).scrollTop() <= 100 && $(".scroll-to-top").fadeOut(500)
            }
        })
    }

  return (
    <div>
          <Link to="mailto: sales@futureisapp.com" className="bt-support-now theme-btn"><i className="fa fa-envelope"></i></Link>
          <Link to="https://wa.me/+1 (408)-785-6680" className="bt-buy-now theme-btn"><i className="fab fa-whatsapp"></i></Link>
          <Link to="" data-target="html" className="scroll-to-target scroll-to-top" onClick={scroll}><i className="fas fa-angle-up"></i></Link>
              <div className="floatbutton">
              <div className="btns_wrap"><Link to="/" className="chat_wrap">
                <span className="icoo"><i className="fas fa-comment"></i></span>
                 <span>Chat With Us</span> </Link><Link to="tel:+1 951-430-0254" className="call_wrap">
                    <span className="icoo"><i className="fas fa-phone"></i></span>
                     <span>+1 951-430-0254</span></Link>
              </div>
              <div className="clickbutton" onClick={sidebar}>
                  <div className="crossplus">START YOUR JOURNEY TO FAME</div>
              </div>
              <div className="banner-form">
                  <h3>Chat With Us to<br/>Avail 50% Discount</h3>
                  <div className="banform">
                      <div className="container">
                          <div className="row">
                              <div className="ban-form">
                                  <form action="https://leads.infinityprojectmanager.com/brand/futureisapp.com/lead" className="popup-form CrudForm cmxform">
                                      <input type="hidden" name="brand_name" value="https://futureisapp.com"/>
                                          <input type="hidden" id="lead_area" name="lead_area" value="https://futureisapp.com"/>
                                              <div className="row">
                                                  <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                                                      <div className="fldset">
                                                        <input name="name" placeholder="Enter your name" required="" />
                                                        </div>
                                                  </div>
                                                  <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                                                      <div className="fldset">
                                                        <input type="email" name="Email" placeholder="Enter email here" required="" />
                                                        </div>
                                                  </div>
                                                  <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                                                      <div className="fldset">
                                                        <input type="tel" name="phone" className="phone-country masking contact" minLength="9" maxLength="11" placeholder="Phone*" data-inputmask="'mask': '(999) 999-9999'" required="" />
                                                      </div>
                                                  </div>
                                                  <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                                                      <div className="fldset">
                                                        <textarea name="message" rows="7" placeholder="Talk About Your Project"></textarea>
                                                        </div>
                                                  </div>
                                                  <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                                                      <div className="fldset">
                                                        <input type="submit" placeholder="Connect With Us" required="" /></div>
                                                  </div>
                                              </div>
                                          </form>
                                      </div>
                              </div>
                          </div>
                      </div>
              </div>
              </div>
              <div className="modal fade" id="staticBackdrop" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <div className="modal-dialog">
                      <div className="modal-content">   
                          <div className="modal-header">
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                  <i className="fa fa-times" aria-hidden="true"></i>
                              </button>
                          </div>
                          <div className="modal-body">
                              <div className="row align-items-center">
                                  <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                                      <div className="modal-body-form">
                                          <h3>Save Upto 30% <span>Limited Time Offer</span></h3>
                                          <form action="https://leads.infinityprojectmanager.com/brand/futureisapp/lead" method="GET" className="popup-form subscribe-form">
                                              <input type="hidden" name="brand_name" value="futureisapp.com"/>
                                                  <input type="hidden" id="lead_area" name="lead_area" value="https://futureisapp.com/"/>
                                                      <div className="popup-form-list">
                                                          <div className="form-group">
                                                              <input type="text" name="name" placeholder="Your Name" required/>
                                                          </div>
                                                          <div className="form-group">
                                                              <input type="email" name="email" placeholder="Your Email" required/>
                                                          </div>
                                                          <div className="form-group">
                                                              <input type="number" name="phone" placeholder="Phone Number" required/>
                                                          </div>
                                                          <div className="form-group">
                                                              <textarea id="message" name="message" placeholder="Your Message" required></textarea>
                                                          </div>
                                                          <div className="form-group form-group-last">
                                                              <button className="submit-btn" type="submit">Submit</button>
                                                          </div>
                                                      </div>
                                                  </form>
                                              </div>
                                      </div>
                                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                          <div className="modal-body-img2">
                                              <LazyLoadImage src={Experiece} alt="img" className="img-fluid"/>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
             </div>
    </div>
  )
}

export default addons
