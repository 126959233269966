import React from 'react'
import Header from '../includes/header';
import Footer from '../includes/footer';
import Banner from '../component/About/banner';
import Crowned from '../component/About/crowned';
import Award from '../component/Home/award';
import Media from '../component/About/media';
import Cta from '../component/About/cta';
import Reviews from '../component/Home/reviews';
import Counter from '../component/Home/counter';
import Services from '../component/About/services';
const About = () => {
  return (
    <div>
          <Header />
          <Banner />
          <Services/>
          <Crowned />
          <Award />
          <Counter />
          <Media />
          <Cta/>
          <Reviews/>
          <Footer />
    </div>
  )
}

export default About
