import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import payment from '../assets/images/payment.webp';
const footer = () => {
  return (
    <>
          <footer>
              <div className="container-fluid">
                  <div className="row">
                      <div className="col-lg-6 col-12 col-md-6 col-sm-12 col-xl-6">
                          <Link className="call_btn" to="void:;" >Let's Chat <i className="fal fa-long-arrow-up"></i></Link>
                      </div>
                      <div className="col-lg-3 col-12 col-md-3 col-sm-6 col-xl-3">
                          <div className="footer-navigation">
                              <h3></h3>
                              <ul>
                                  <li><Link to="/Portfolio">Portfolio</Link></li>
                                  <li><Link to="/">Services </Link></li>
                                  <li><Link to="/About">About </Link></li>
                                  <li><Link to="/Contact">Contact</Link></li>
                                  <li><Link to="tel:+1 (408)-785-6680 ">+1 (408)-785-6680 </Link></li>
                                  <li><Link to="mailto:sales@futureisapps.com">sales@futureisapps.com</Link></li>
                              </ul>
                          </div>
                      </div>
                      <div className="col-lg-3 col-12 col-md-3 col-sm-6 col-xl-3">
                          <div className="footer-navigation">
                              <ul>
                                  <li><Link to="/services">Services</Link></li>
                                  <li><Link to="/android-app-development">Android App Development </Link></li>
                                  <li><Link to="/game-app-development">Game App Development </Link></li>
                                  <li><Link to="/nft-app-development">Nft App Development </Link></li>
                                  <li><Link to="/web-app-development">Web App Development </Link></li>
                                  <li><Link to="/cross-platform-app-development">Cross Platform App Development </Link></li>
                              </ul>
                          </div>
                      </div>
                  </div>
                  <div className="footer-bottom">
                      <div className="row d-flex align-items-center justify-content-between mt-4">
                          <div className="col-lg-4 col-12">
                              <p>Copyright © {moment().year()} Future Is App. All rights reserved.</p>
                          </div>
                          <div className="col-lg-4  col-12">
                              <LazyLoadImage src={payment} alt="payment"/>
                          </div>
                          <div className="col-lg-4 col-12 ">
                            <div className="policy-links">
                                  <Link to="/privacy-policy">Privacy Policy</Link> 
                                  <Link to="/terms-conditions">Terms &amp; Condition</Link>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </footer>
    </>
  )
}

export default footer
