import React from 'react'

const counter = () => {
  return (
    <div>
          <section>
              <div className="counter">
                  <div className="container-fluid">
                      <div className="count_data">
                          <div>
                              <h4 id="number1">100</h4>
                              <p>Successfully Completed Projects</p>
                          </div>
                          <div>
                              <h4 id="number2">300</h4>
                              <p>In-House Engineers</p>
                          </div>
                          <div>
                              <h4 id="number3">20</h4>
                              <p>Industries <served></served>
                              </p>
                          </div>
                          <div>
                              <h4 id="number4">70</h4>
                              <p>Client Retention Rate</p>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
    </div>
  )
}

export default counter
