import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom';
import Banner_Image from '../../assets/images/about-banner.webp';
const banner = () => {
  return (
          <section>
              <div className="about_banner">
                  <div className="container-fluid">
                      <div className="row d-flex align-items-center">
                          <div className="col-12 col-sm-12 col-lg-6 col-xl-6 col-xxl-6 col-md-6">
                              <div className="txt">
                                  <span>About</span>
                                  <h1>Brings Ideas
                                      To Life <i>.</i>
                                  </h1>
                                  <p>With our team of talented developers, designers, and strategists, we create innovative and user-centric applications that transform ideas into reality.</p>
                                  <div className="btns">
                                  <Link to="tel:+1 (408)-785-6680 ">Schedule A Call</Link>
                                  <Link to="/" >Let's Chat <i className="fal fa-long-arrow-up"></i></Link>
                                  </div>
                              </div>
                          </div>
                          <div className="col-12 col-sm-12 col-lg-6 col-xl-6 col-xxl-6 col-md-6">
                              <div className="img_banner">
                                 <LazyLoadImage src={Banner_Image} alt="about"/>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
          </section>
  )
}

export default banner
