import React from 'react'
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Header from '../includes/header';
import Footer from '../includes/footer';
import Banner from '../component/Blog/banner';
import BlogSlider from '../component/Blog/blogslider';
import BlogCard from '../component/Blog/blogcard';
import b11 from '../assets/images/b11.webp';
import blogAdd from "../json/blogadd.json";



const Blog = () => {
  return (
    <div>
          <Header />
          <Banner />
          <BlogSlider/>
          <BlogCard/>
          <section>
              <div class="old_blog">
                  <div class="container-fluid">
                      <div class="row">
                          <div class="col-12 col-sm-12 col-md-7 col-xl-7 col-xxl-7 col-lg-7">
                              <div class="left_txt">
                                  <h4>Old Articles.</h4>
                                  {
                                    blogAdd.map((item)=>{
                                        return(
                                            <div class="crd">
                                                <LazyLoadImage src={item.img} alt="b9" />
                                                <div>
                                                    <h6>{item.subtitle}</h6>
                                                    <h5>{item.title}
                                                        <Link to="/"><i class="fal fa-long-arrow-right"></i></Link>
                                                    </h5>
                                                    <p> {item.desc}</p>
                                                    <span><b>{item.name}</b> {item.detail}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                  }
                              
                                

                              </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-5 col-xl-5 col-xxl-5 col-lg-5">
                              <div class="right_txt">
                                  <h4>Popular Categories.</h4>
                                  <div class="category_btn">
                                      <Link to="/">Latest</Link>
                                      <Link to="/">Technology</Link>
                                      <Link to="/">Android</Link>
                                      <Link to="/">Mobile</Link>
                                      <Link to="/">Companies</Link>
                                      <Link to="/">Startup</Link>
                                      <Link to="/">Websites</Link>
                                      <Link to="/">Tech Industry</Link>
                                      <Link to="/">Social Media</Link>
                                      <Link to="/">Iphone</Link>
                                      <Link to="/">General</Link>
                                      <Link to="/">App Review</Link>
                                      <Link to="/">Networking</Link>
                                      <Link to="/">Trending</Link>
                                      <Link to="/">Dating Apps</Link>
                                  </div>
                                  <div class="frm">
                                      <form action="">
                                          <input type="text" required="" placeholder="Search Blog"/>
                                              <button type="submit">
                                                  <i class="fas fa-search"></i>
                                              </button>
                                      </form>
                                  </div>
                                  <div class="talk">
                                      <div class="txt">
                                          <h3>Talk
                                              To Us <span>!</span> </h3>
                                          <p>Future Is App Is An Extension Of Your Marketing Team – Bringing Strategy, Expertise, And Execution To The Areas That You Need It Most.</p>
                                          <Link to="tel:+1 (408)-785-6680 ">Schedule A Call</Link>
                                      </div>
                                      <LazyLoadImage src={b11} alt="b11"/>
                                  </div>

                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <Footer />
    </div>
  )
}

export default Blog
