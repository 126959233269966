import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import BloCgCard from "../../json/blog_card.json";

const blogcard = () => {
  return (
    <div>
          <section>
              <div className="blog_card2">
                  <div className="container-fluid">
                      <div className="row">
                             {
                                 BloCgCard.map((item)=>{
                                      return(
                                        <div className="col-12 col-sm-12 col-md-6 col-xl-4 col-xxl-4 col-lg-4">
                                            <div className="item">
                                                  <LazyLoadImage src={item.img} alt="blog"/>
                                                <div className="p-3">
                                                    <h3>{item.title}
                                                        <Link to="/"><i className="fal fa-long-arrow-right"></i></Link>
                                                    </h3>
                                                    <p>{item.desc}</p>
                                                    <span><b>{item.name}</b> {item.detail}</span>
                                                </div>
                                            </div>
                                        </div>
                                      )
                                 })
                             }
                         
                      </div>
                  </div>
              </div>
          </section>
    </div>
  )
}

export default blogcard
