import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import Crowned_Image from '../../assets/images/aboutphone.webp';

const crowned = () => {
  return (
          <section>
              <div className="crowned">
                  <div className="container-fluid">
                      <div className="row d-flex align-items-center">
                          <div className="col-12 col-sm-12 col-lg-6 col-xl-6 col-xxl-6 col-md-12 order-md-2">
                              <div className="img">
                              <LazyLoadImage src={Crowned_Image} alt="aboutphone"/>
                              </div>
                          </div>
                          <div className="col-12 col-sm-12 col-lg-6 col-xl-6 col-xxl-6 col-md-12 order-md-1">
                              <div className="txt">
                                  <h5> Work with the Tech Leader.</h5>
                                  <h4>Shaping the Future of Mobile Technology</h4>
                                  <p>We are a leading app development company dedicated to transforming ideas into exceptional mobile experiences. With our team of talented developers, designers, and strategists, we create innovative and user-centric applications that redefine the way businesses and individuals interact with technology. Our commitment to innovation, excellence, and collaboration drives us to deliver cutting-edge, scalable solutions that empower our clients to thrive in the digital age.</p>
                                  <div className="btns">
                                      <Link to="tel:+1 (408)-785-6680">Schedule A Call</Link>
                                      <Link to="/">Let's Chat <i className="fal fa-long-arrow-up"></i></Link>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
  )
}

export default crowned
