import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import services_choose from '../../../assets/images/services-choose.webp';

const cta = () => {
  return (
    <div>
          <section className="cta_banner services">
              <div className="container-fluid">
                  <div className="row">
                      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="cta_content">
                              <h2>Why Partner with Us for Your NFT App Design &amp; Development <span> Journey? </span> </h2>
                              <p>We harness our in-depth understanding of the crypto-market, technical expertise, and innovative design approach to create top-tier NFT apps. We understand the unique aspects of NFTs and how to leverage them for your advantage. Our apps not only meet current market demands but also anticipate future trends, thus positioning our clients ahead of the curve. We prioritize user-centric design, secure transactions, scalability, and post-deployment support in our NFT app development journey, making us a trusted choice for businesses worldwide.</p>
                              <div className="btns">
                                  <Link href="tel:"> Schedule A Call </Link>
                                  <Link to="/"> Let's chat <i className="fal fa-long-arrow-up"></i></Link>
                              </div>
                          </div>
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="cta_img">
                              <LazyLoadImage src={services_choose} alt="services"/>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
    </div>
  )
}

export default cta
