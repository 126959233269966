import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Portfolio from '../../json/portfolio.json'

const portfolio = () => {
    return (
        <div>
            <section>
                <div class="portfolio">
                    <div class="portfolio_grid">
                        {
                            Portfolio.map((item)=>{
                                return(
                                    <LazyLoadImage src={item.img} alt="portfolio" />
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}

export default portfolio
