import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
// component area 
import Header from '../includes/header';
import Footer from '../includes/footer';
import Banner from '../component/Home/Banner';
import Process from '../component/Home/process';
import Technology from '../component/Home/technology';
import Cta from '../component/Home/cta';
import Services from '../component/Home/services';
import Award from '../component/Home/award';
import Reviews from '../component/Home/reviews';
// img area 
import Crowned from '../assets/images/crowned.webp';
// title area 
import useDocumentTitle from '../useDocumentTitle.jsx'

const Home = () => {
  useDocumentTitle('Future Is Apps | App Design & Development Services Agency')


  return (
    <div>
      <Header />
      <Banner />
      <Process />
      <Technology />
      <Cta />
      <Services />
      <Award />
      {/* cta  */}
      <section>
        <div className="cta">
          <h6>Get Your Brand the App It Needs Because Your Customers
            <span>Won't Keep on Waiting</span>
            <a href="tel:+1 (408)-785-6680">Get Your App Here <i className="fal fa-long-arrow-up"></i></a>
          </h6>
        </div>
      </section>
      {/* cta  */}
      {/* crowned  */}
      <section>
        <div className="crowned">
          <div className="container-fluid">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-sm-12 col-lg-6 col-xl-6 col-xxl-6 col-md-6">
                <div className="img">
                  <LazyLoadImage src={Crowned} alt="crowned"/>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-lg-6 col-xl-6 col-xxl-6 col-md-6">
                <div className="txt">
                  <h5>Be The First To Know</h5>
                  <h4>Trend-Setting App Design &amp; Development</h4>
                  <p>If you don't bring something new to the technology table, no one will even look at it. That's why at our app design and development agency, we always strive to create something custom and innovative for our clients so that when you launch your app, no one can stay away from it. </p>
                  <div className="btns">
                    <a href="tel:+1 (408)-785-6680 ">Schedule A Call</a>
                    <a href="void:;" >Let's Chat <i className="fal fa-long-arrow-up"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* crowned  */}
      <Reviews />
      <Footer />
    </div>
  )
}

export default Home
