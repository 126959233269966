import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Header from '../includes/header';
import Footer from '../includes/footer';
import Contact_img from '../assets/images/contact.webp';

const Contact = () => {
  return (
    <div>
          <Header Contactstyle={{ background: '#fff' }} />
          <section>
              <div className="contact_page">
                  <div className="container-fluid">
                      <div className="row d-flex align-items-center">
                          <div className="col-12 col-sm-12 col-lg-7 col-xl-7 col-xxl-7 col-md-7">
                              <div className="LazyLoadImage_area">
                                  <LazyLoadImage src={Contact_img} alt="contact"/>

                              </div>
                          </div>
                          <div className="col-12 col-sm-12 col-lg-5 col-xl-5 col-xxl-5 col-md-5">
                              <div className="contact_frm">
                                  <h4>What’s Up
                                      <span>?</span>
                                  </h4>
                                  <p>Tell Us About Your Project. We Respond To Almost Every Inquiry Within One Business Day. Really!</p>
                                  <div className="row">
                                      <form action="https://leads.infinityprojectmanager.com/brand/futureisapp.com/lead" className="popup-form CrudForm cmxform">
                                          <input type="hidden" name="brand_name" value="https://futureisapp.com"/>
                                              <input type="hidden" id="lead_area" name="lead_area" value="https://futureisapp.com"/>
                                                  <div className="row">
                                                      <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                                                          <label>Name</label>
                                                          <input name="name" placeholder="John Doe" required=""/>
                                                      </div>
                                                      <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                                                          <label>Email</label>
                                                          <input type="email" name="Email" placeholder="Example@example.com" required=""/>
                                                      </div>
                                                      <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                                                          <label>Contact Number</label>
                                                          <input type="tel" name="phone" minlength="9" maxLength="11" placeholder="+1 (234) 567890" data-inputmask="'mask': '(999) 999-9999'" required=""/>

                                                      </div>
                                                      <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                                                          <label>Message</label>
                                                          <textarea name="message" rows="7" placeholder="Text Here..."></textarea>
                                                      </div>
                                                      <div className="col-lg-12 col-12 col-sm-12 col-md-12">
                                                          <button type="submit">Submit</button>
                                                      </div>
                                                  </div>
                                              </form>
                                          </div>

                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
          </section>
          <Footer/>
    </div>
  )
}

export default Contact
