import React from 'react'
import Header from '../includes/header';
import Footer from '../includes/footer';
import { Link } from 'react-router-dom'
const privacypolicy = () => {
  return (
    <div>
      <Header />
      <main>
        <section>
          <div className="about_banner portfolio">
            <div className="container-fluid">
              <div className="txt">
                <h1>Privacy Policy <i>.</i></h1>
                <p>Please read this Privacy Policy carefully before using this website</p>
                <div className="btns">
                  <Link to="tel:+1 (408)-785-6680 ">Schedule A Call</Link>
                  <Link to="void:;" >Let's Chat <i className="fal fa-long-arrow-up"></i></Link>
                </div>
              </div>

            </div>
          </div>
        </section>
        <section>
          <div className="privacy_txt">
            <div className="container-fluid">
              <p>Thank you for becoming a part of the Future Is Apps. Your privacy is important to us, and we are committed to protecting your personal information. If you have questions about our policy or practices regarding your personal information, please contact us.</p>
              <p>When you use our services on our website, you are putting your trust in us. We take the privacy of our customers very seriously. In this privacy statement, we aim to explain to you in the most straightforward manner possible what information we gather, how we use it, and what rights you have regarding it. We hope that you consider it because it is significant. If there are any provisions within this privacy policy that you disagree with, please stop using our services immediately.</p>
              <p>This privacy statement applies to any information we gather through our and/or any related services, sales, marketing or events (we refer to them all as the "Services"). Please read the privacy statement below carefully since it will assist you in making educated choices regarding how to share your personal information with us.</p>
              <h4>What Information Do We Collect And How Do We Use It?</h4>
              <p>We collect various types of information from you when you visit our website or use our Services. Some of this information may be personal information that can identify you, while other data is aggregated and cannot identify you personally.</p>
              <p>The type of information we may collect includes:</p>
              <ul>
                <li>Contact information such as your name, email address and/or telephone number</li>
                <li>Payment information such as credit card details</li>
                <li>Your IP address or other device identifiers</li>
                <li>Information about how you interact with our website or Services, including what pages you visit, links you click on and searches you make Location data and any other information you choose to provide.</li>
                <p>We use the information we collect about you for a variety of purposes, including:</p>
              </ul>
              <ul>
                <li>To provide, personalize and improve our website or Services</li>
                <li>To process your transactions and payments</li>
                <li>For marketing and advertising purposes</li>
                <li>To communicate with you about our website or Services</li>
                <li>For research and development purposes</li>
                <li>To detect, investigate and prevent fraud, illegal activities or misuse of our website or Services</li>
                <li>To protect the rights, property or safety of Future Is Apps, our customers or others</li>
              </ul>
              <h3>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
              <p>In a nutshell, we may collect and store your information with the help of cookies and other tracking technologies. Cookies and similar tracking technologies (such as web beacons and pixels) are used to access or store information. Our Cookie Policy goes into further detail about how we use these tools, as well as how you can refuse cookies.</p>
              <h4>You have certain rights under law when it comes to your personal information. These include the right to:</h4>
              <p>The type of information we may collect includes:</p>
              <ul>
                <li>Request a copy of your personal information</li>
                <li>Request that we delete your personal information in certain circumstances</li>
                <li>Object to our use of your personal information for marketing purposes</li>
                <li>Request that we restrict our use of your personal information in certain circumstances.</li>
              </ul>
              <p>You can exercise these rights by contacting us using the details set out below. Please note that there may be circumstances where we are unable to comply with your request, such as where the information is necessary for us to comply with our legal obligations or where your request would be unduly onerous for us.</p>
              <h3>HOW DO WE USE YOUR INFORMATION?</h3>
              <p>We may use your information for the following purposes:</p>
              <ul>
                <li>To provide and improve our Services. This includes using your information to assist you in transactions, to tailor content, advertisements and suggestions to you, and to detect, investigate and prevent fraudulent, illegal or unauthorized activities</li>
                <li>For marketing purposes. This includes sending you promotional messages, marketing, advertising and other information that may be of interest to you. You can opt out of receiving promotional messages from us by following the instructions in those messages</li>
                <li>For research and development purposes. This helps us to improve our current Services and develop new ones</li>
                <li>To communicate with you. We may contact you for customer service purposes, to resolve disputes, collect fees or to troubleshoot problems with our Services, or as otherwise necessary to contact you to enforce our terms and conditions</li>
                <li>For billing purposes. We use your information to process payment for the Services</li>
                <li>To detect, investigate and prevent fraudulent, illegal or unauthorized activities. This helps us keep our community safe and secure and</li>
                <li>As required by law or as necessary to protect the rights, property or safety of Future Is Apps, our customers or others. This includes using your information in connection with legal proceedings, investigations and claims.</li>

              </ul>
              <h3>DO WE MAKE UPDATES TO THIS POLICY?</h3>
              <p>In summary, yes, we will update this policy as required to keep us in line with applicable legislation.</p>
              <p>We regularly update our privacy policy in order to keep our users informed of how we are protecting their information. If there are any material changes made to the policy, we will notify you either by posting a notice or sending you a direct notification. For your convenience, we encourage you to review this privacy policy frequently.</p>
            </div>
          </div>
        </section>
      </main>
      <Footer/>
    </div>
  )
}

export default privacypolicy
